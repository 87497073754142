import css from './style/main.scss';
const $ = require('jquery');
window.jQuery = $;
import 'bootstrap';
//import './js/tarteaucitron/tarteaucitron.js';
// import 'jquery';
//import './js/switch-radio.js';
//import './js/sticky-form.js';
//import './js/visuel-slider'
// import './js/slider.js';
// Autocompletion
import './js/jquery-ui.min.js';
// Validation du form
import './js/jquery.validate.min.js';
import './js/sticky-menu.js';

/*if ($(window).width() < 1439) {
    $( document ).ready(function() {
        $("#slider-mob").height($("#slider-mob").width()*0.6986);
        $( window ).resize(function() {
            $("#slider-mob").height($("#slider-mob").width()*0.6986);
        });
    });
    $( window ).resize(function() {
        $("#slider-mob").height($("#slider-mob").width()*0.6986);
    });
}*/

$(function ($) {
    $(document).ready(function () {
        $("#form-contact").validate({
            rules: {
                optin:{ required:true }
            },
            messages: {
                optin: {
                    required: 'veuillez saisir ce champ'
                },
                civilite: {
                    required: 'veuillez saisir ce champ'
                },
                souhaitAction: {
                    required: 'veuillez saisir ce champ'
                }
            }
        });
        // FORM
        $('#form-contact').on('submit', function(e) {
            e.preventDefault();
            $('#form-contact').validate();
            if ($('#form-contact').valid()) {
                $('#DivLoader').show();
                var message = $('#form-contact').serialize();
                $.ajax({
                    type: 'POST',
                    cache: false,
                    url: 'actions/submit.php',
                    data: message,
                }).done(function(data) {
                        console.log('sended');
                        console.log(data);
                        $('#modal-thanks').modal('show');
                        $('#form-contact').find('input').not('[type="hidden"]').val('');
                        $('#DivLoader').hide(); // uncomment to disable loader on submit loader after submit
                        $('body').append("<script> fbq('track', 'CompleteRegistration'); </script>");    // Facebook tracker, use it with facebook pixel
                });
            }
        });
        
    });
});